.second-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #000000;
}

.news-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 65px;
    text-align: center;
    color: #94B71B;
}

.news {
    display: flex;
    justify-content: center;
    .news-box {
        width: 492.04px;
        height: 300px;
        display: inline-flex;
        background: #94B71B;
        box-shadow: 4.42478px 8.84956px 10.6195px rgba(0, 0, 0, 0.25);
        margin: 0 25px 0 25px;
        padding: 5px;
        border-radius: 15.0442px;
        flex-direction: column;

        .box {
            position: absolute;
            width: 492.04px;
            margin-top: 340px;
        }
        .box:nth-child(2){
            margin-top: 50px !important;
        }

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 31.8584px;
            line-height: 39px;
            text-align: center;
            color: #fff;
            padding: 5px;
            width: 100%;
        }

        .newsImage {
            background-image: url("../../assets/images/newsimg.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 100%;
            border-radius: 15.0442px;
            height: 100%;
        }
    }
}

@media screen and (max-width: 425px) {
    .news-bg {
        margin-top: 170px;
        .news-title {
            font-size: 16px;
            padding: 0;
            margin: 0;
        }
        .news-box:nth-child(2) {
            display: none;
        }
        .news-box {
            width: 100%;
            display: flex;
            height: 125px;
            background: none;
            box-shadow: none;
            flex-direction: row;
            .title {
                color: #94B71B;
                font-size: 16px;
                width: auto;
                padding: 0;
                text-align: left;
                line-height: 0;
                margin-top: 10px;
            }
            .second-title {
                display: none;
            }
            .newsImage {
                width: 107px;
                height: 107px;
                margin-right: 17px;
            }
            .box {
                margin: 0;
                width: 225px;
                color: #b6b6b6;
                position: relative;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
            }
            .box:nth-child(2){
                margin-top: 0px !important;
            }
        }
    }
}
