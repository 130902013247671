.news-card {
    width: 50%;
    margin: 0 103px;

    .title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        margin-top: 38px;
        color: #94B71B;
    }
    .text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        margin-top: 38px;

        color: #94B71B;
        margin-bottom: 200px;
    }
}
