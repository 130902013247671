.footer,
.footer-mobile {
    position: relative;
    width: 100%;
    height: 500px;
    left: 0px;
    background: #434343;
    display: flex;
    .footer-section {
        display: inline-block;
        margin-left: 111px;
        margin-top: 81px;
        a {
            display: block;
            font-weight: 250;
            font-size: 24px;
            line-height: 29px;
            color: #ffffff;
            text-decoration: none;
            padding: 11px 0;
        }

        div {
            font-weight: 250;
            font-size: 24px;
            line-height: 29px;
            color: #ffffff;
            text-decoration: none;
        }

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 11px;
            color: #94B71B;
        }
    }
}

.footer-mobile {
    display: none;
}

@media screen and (max-width: 425px) {
    .footer {
        display: none;
    }

    .footer-mobile {
        display: flex;
        flex-direction: column;
        height: 500px;

        .footer-section {
            margin: 15px 32px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                margin-top: 0px !important;
            }
            div {
                font-weight: 200;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
@media screen and (min-width:1024px) and (max-width: 1440px) {
    .footer-section {
        margin-top: 60px !important;
    }
    .footer-section:nth-child(1) {
        margin-left: 30px;
    }
    .footer-section:nth-child(2) {
        margin-left: 20px;
    }
    .footer-section:nth-child(3) {
        margin-left: 60px;
    }
    
}