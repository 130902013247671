.work__detail {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main__image {
        width: 1200px;
        margin-top: 47px;
    }

    .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: #94B71B;
        margin-top: 31px;
        .sub__title {
            font-weight: 400;
            line-height: 29px;
            margin-top: 39px;
        }
    }

    .bottom__section {
        margin-top: 71px;
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        background: #94B71B;
        .second__main__image {
            margin-top: 28px;
            .item__title {
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;

                color: #ffffff;
            }
            .item__description {
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
            }
            img {
                width: 1200px;
            }
        }
        .items__box {
            display: flex;
            justify-content: center;
            margin-bottom: 32px;
            flex-wrap: wrap;
            .items {
                width: 44%;
                margin: 0 14px;
                margin-top: 36px;

                .item__title {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;

                    color: #ffffff;
                }
                .item__description {
                    margin-top: 16px;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                }

                img {
                    width: 100%;
                    height:326px ;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .work__detail {
        .title {
            font-size: 15px !important;
            line-height: 18px !important;
            margin-top: 29px;
            .sub__title {
                margin-top: 31px !important;
            }
        }

        .main__image {
            width: 100% !important;
            margin-top: 18px !important;
        }
        .bottom__section {
            margin-top: 13px !important;

            .second__main__image {
                margin-top: 0 !important;
                .item__title {
                    text-align: center;
                    padding: 19px 0;
                    font-size: 15px !important;
                    line-height: 18px !important;
                }
                .item__description {
                    display: none;
                }
                img {
                    width: 100% !important;
                }
            }
            .items__box {
                flex-direction: column;
                .items {
                    width: 100% !important;
                    margin: 0 !important;
                }
                .item__title {
                    text-align: center;
                    padding: 19px 0;
                    font-size: 15px !important;
                    line-height: 18px !important;
                }
                .item__description {
                    display: none;
                }
            }
        }
    }
}
