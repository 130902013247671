.social-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;

    background: #94B71B;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
.social {
    display: flex;
    justify-content: center;
    a {
        margin: 30px;
    }
}
.phones {
    margin-top: 38px;
    font-weight: 700;
    font-size: 38.9804px;
    line-height: 48px;
    text-align: center;

    color: #b6b6b6;
}
.map{
    border: 0;
    border-radius: 27px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 61px;
}

@media screen and (max-width:425px) {

    .social-title {
        font-size: 16px;
        line-height: 20px;
    }

    .social {
        img {
            width: 50px;
        }
    }

    .phones {
        font-size: 16px;
        line-height: 20px;
    }

    .map {
        margin-top: 53px;
        iframe {
            height: 204px;
            width: 90%;
        }
    }
    

    
}