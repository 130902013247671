* {
    padding: 0;
    margin: 0;
    font-family: "Fredoka", sans-serif;
}

.banner {
    background-image: url("../../assets/images/mainBanner.png");
    width: 100%;
    height: 1251px;
    position: relative;
    background-size: cover;

    & .title {
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;
        padding-bottom: 28px;
        background: #94B71B;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    & .pred {
        position: relative;
    }

    & a {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #c1c1c1;
        text-decoration: none;
        display: block;
        width: 45%;
        margin-bottom: 30px;
    }
    & .banner-box {
        top: 75px;
        left: 363px;
        position: relative;
        width: 70%;
    }
}
.additional-info-box {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 185px;
    left: 50%;
    transform: translateX(-50%);
    .add-title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 47.0284px;
        line-height: 57px;
        width: 551.28px;

        background: #94B71B;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-right: 202px;
    }
    .add {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 300;
        font-size: 26.3362px;
        line-height: 32px;
        width: 100%;
        color: #434343;
        padding: 20px 20px 0 0;
    }
    .call {
        margin-right: 152px;
        margin-bottom: 31px;
    }
    .tg {
        margin-bottom: 31px;
        text-align: center;
    }
}
.partners {
    height: 182px;
    margin-top: 164px;
    .slide {
        width: 70%;
        top: 25px;
        .slide-item {
            display: flex;
            justify-content: center;
        }
    }
    .title {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        background: #94B71B;
        text-align: center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-bottom: 32px;
    }
}

.footer-image {
    background: url("../../assets/images/footerBanner.png");
    width: 100%;
    height: 1060px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}

.trans-bg {
    background-image: url("../../assets/images/advantage.png");
    height: 1172.26px;
    background-size: cover;
}

@media screen and (max-width: 425px) {
    .banner {
        position: relative;
        height: 227.62px;
        top: 255px;
        a {
            font-size: 12px;
            text-align: center;
            width: 289px;
            color: #fff;
            background: #c4c4c4;
            border-radius: 6px;
        }
        a:nth-child(1) {
            background: #94B71B;
        }
        .title {
            font-weight: 700;
            font-size: 22.3716px;
            line-height: 27px;
        }

        .banner-box {
            top: -235px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            left: 50% !important;
            transform: translateX(-50%);
        }
    }
    .additional-info-box {
        margin-top: 62px;

        .add-title {
            font-size: 1rem;
            width: 155px;
            margin-right: 0;
            line-height: 19px;
        }
        .add {
            font-size: 10px;
            line-height: 19px;
            margin: 0;
            width: auto;
        }
        .call,
        .tg {
            padding: 0 !important;
            font-size: 14px;
            width: 100px;
        }
        span:nth-child(2),
        span:nth-child(3) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            text-align: center;
        }

        span {
            height: 100px;
            img {
                width: 48px;
                height: 48px;
            }
        }
    }

    .partners{
        margin-top: 275px;
        .slide {
            width: 100%;
        }
        .title {
            font-size: 16px;
            margin-bottom: 0;
        }
        .slide-item {
            img {
                width: 64px;
            }
        }
    }
    .footer-image {
        height: 235px;
    }
}
