// .advertisement {
//     position: relative;
//     width: 100%;
//     height: 437px;
//     background: #e98181;
// }
.our-services {
    .title {
        font-weight: 700;
        font-size: 64px;
        line-height: 78px;

        background: #94B71B;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        margin-left: 235px;
        margin-bottom: 27px;
        margin-top: 51px;
    }
    ul {
        margin-left: 235px;
        padding: 0;
        list-style: none;
        width: 50%;
    }
    ul li {
        padding: 20px;
        font-weight: 700;
        font-size: 27.5943px;
        line-height: 40px;
        cursor: pointer;
        color: #94B71B;
    }
    ul li:hover {
        background: linear-gradient(
            90deg,
            #6cbe34 10.13%,
            #82c22d 38.78%,
            #b0cb1f 100%
        );
        color: #fff;
    }
    .imagebox {
        width: 50%;
    }
    .service-box {
        display: flex;
    }
}

.service-info {
    background: #94B71B;
    height: 237px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 121px;
    div {
        font-weight: 700;
        font-size: 30.9726px;
        line-height: 38px;
        color: #fff;
        width: 60%;
    }
}

.price {
    margin-left: 235px;
    display: flex;
    padding-bottom: 100px;
    .text {
        width: 50%;
        margin-left: 103px;
    }
    .imgs {
        margin-top: 84px;
        position: relative;
        width: 50%;
    }
    .main-title {
        font-weight: 700;
        font-size: 36px;
        line-height: 144.9%;
        margin-top: 58px;
        margin-bottom: 38px;
        color: #94B71B;
    }
    .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 49px;
        background: #94B71B;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    ul {
        list-style: none;
    }
    ul li::before {
        content: "⬤";
        color: #68b433;
        font-weight: bold;
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
    }
    ul li {
        font-weight: 700;
        font-size: 22.9996px;
        line-height: 28px;
        padding: 10px;

        color: #c1c1c1;
    }
}

@media screen and (max-width: 425px) {
    .advertisement {
        display: none;
    }
    .our-services {
        .title {
            font-weight: 700;
            font-size: 16px !important;
            line-height: 20px;
            margin: 20px 0;
            width: 100%;
            text-align: center;
        }
        .imagebox {
            width: 100%;
        }

        ul {
            margin-left: 0;
            width: 100%;
            text-align: center;
            li {
                font-weight: 700;
                font-size: 16px;
                line-height: 145.9%;
                width: 100%;
                padding: 8px 0;
                background: #94B71B;
                color: #fff;
            }
            li:hover {
                background: #a6d33e;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
            }
        }
    }
    .service-box {
        flex-direction: column;
    }
    .price {
        margin-left: 0;
        flex-direction: column;
        padding-bottom: 30px;
        
        .imgs {
            margin-top: 50px;
            position: relative;
            width: 100%;
            left: 0%;
            height: 440px;
        }
        .text {
            width: 95%;
            margin-left: 17px;
            .main-title {
                font-weight: 700;
                font-size: 16px;
                line-height: 144.9%;
                text-align: center;
                margin-bottom: 31px;
                margin-top: 24px;
            }
            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 144.9%;
                text-align: center;
                margin-bottom: 30px;
            }

            ul {
                width: 100%;
                li {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    width: 100%;
                    padding: 10px 0 ;
                }
            }
        }
    }
    .service-info {
        margin-top: 41px;
        background: none;
        height: auto;
        div {
            color: #92bf2b;
            font-size: 16px;
            line-height: 20px;
            width: 22.25rem;
        }
    }
}

@media screen and (min-width:1024px) and (max-width:1440px) {
    .our-services {
        ul {
            margin-left: 80px !important;
        }
        .title {
            margin-left: 100px;
        }
    }    
}