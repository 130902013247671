.single-service {
    margin-left: 274px;
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    margin-top: 70px;

    
    .info-section-img {
        width: 730px;
        height: 619px;
        display: flex;
        justify-content: center;
        background: #92BF2B;
        border-radius: 33px;
        margin-right: 107px;
        img {
            margin-left: 91px;
            width: 645px;
        }
    }
    
    .info-section {
        width: 625px;
        font-weight: 700;
        font-size: 32.2238px;
        line-height: 39px;
        color: #b6b6b6;

        .service-title {
            font-weight: 700;
            font-size: 64px;
            line-height: 78px;
            padding: 0 0 50px 0;
            color: #92BF2B;
        }
    }
    
}
.imgs__and__info {
    display: flex;
    justify-content: center;
    width: 100%;
    .one__service__info {
        div {
            font-weight: 700;
            color: #767676;

        }
    }
    .imgs {
        display: flex;
        margin-left: 60px;
    }
}

.custom__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 36px;

    img {
        border-radius: 24px;
    }

    .gobtn {
        background: none;
        outline:none;
        border: none;
        cursor: pointer;
        padding:0 32px;

        i {
            font-size: 42px;
        }
    }
}

.price-section {
    display: flex;
    justify-content: center;

    .price-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        margin-top: 73px;
        margin-bottom: 79px;
        background: linear-gradient(90deg, #92BF2B 0%, #92BF2B 116.46%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .text {
        font-weight: 700;
        font-size: 22px;
        line-height: 35px;
        margin-left: 0;
        color: #000000;
    }

    .price {
        display: flex;
        flex-direction: column;
        width: 574px;
    }
}

.map-title {
    font-size: 64px;
    line-height: 78px;

    background: linear-gradient(90deg, #92BF2B 0%, #92BF2B 116.46%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    background-clip: text;
    margin-bottom: 41px;
}

.map-bg {
    height: 826px;
    display: flex;
    background: linear-gradient(90deg, #92BF2B 4.96%, #92BF2B 98.21%);
}

@media screen and (max-width: 425px) {
    .single-service {
        flex-direction: column;
        margin-left: 0;

        .info-section-img {
            display: flex;
            width: 100%;
            border-radius: 0;
            margin-right: 0;
            height: 341px;
            align-items: center;
            img {
                background: #fff;
                width: 320px;
                height: 250px;
                border-radius: 20px;
                margin-left: 0;
            }
        }
        .info-section {
            margin-top: 29px;
            width: 295px;
            font-size: 16px;
            line-height: 20px;
        }
    }
    .price-section {
        flex-direction: column;

        .price {
            width: 100%;
            .price-title {
                width: 292px;
                font-size: 16px;
                line-height: 20px;
                margin-top: 23px;
                margin-bottom: 16px;
                margin-left: 31px;
            }
            .text {
                font-weight: 700;

                font-size: 16px;
                line-height: 20px;
                margin-left: 0;
                margin-left: 31px;
                width: auto;
            }
        }
    }
    .map-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }
    .map-bg {
        background: none;
        height: auto;
        margin: 0 !important;

        iframe {
            margin: 0 !important;
            border-radius: 0;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .single-service {
        margin-left: 110px;
    }
    .price {
        margin-left: 105px;
    }
}
