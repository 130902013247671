.ourworks {
    .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: #94B71B;
        margin-top: 31px;
    }

    .works__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 37px;
        .work__item {
            margin: 27px 13px;
            height: 172px;
            background: #94B71B;
            display: inline-flex;
            width: 30%;
            align-items: center;
            justify-content: center;
            border-radius: 21px;
            transition: 0.3s;
            cursor: pointer;
            .work__title {
                margin-left: 58px;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;

                color: #ffffff;
            }
        }
        .work__item:hover {
            background: #5b8e18;
        }
    }
}

.image__box {
    margin-top: 77px;

    img {
        width: 33%;
    }
}

@media screen and (max-width: 425px) {
    .works__box {
        flex-direction: column;
        .work__item {
            width: 93% !important;
            height: 113.69px !important;
            justify-content: flex-start !important;
            margin: 7px 13px !important;
            img {
                width: 176.49px;
            }
            .info__section {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .work__title {
                font-size: 16px !important;
                line-height: 20px !important;
                margin-left: 40px !important;
            }
            .left {
                position: absolute;
                margin-left: 0px !important;
                width: 40%;
            }
            .left__img {
                position: absolute;
                left: 50%;
            }
        }
    }
    .image__box {
        img {
            width: 100%;
        }
    }
}

@media screen and (min-width:1024px) and (max-width: 1440px) {
    .ourworks {
        .works__box {
            .work__item {
                img {
                    width: 240px;
                }
                .work__title {
                    margin-left: 20px;
                }
            }
        }
    }
}
