.about-banner {
    background: url("../../assets/images/about.png");
    width: 100%;
    height: 150vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    & .about-caption {
        position: absolute;
        margin-left: 397px;
        margin-top: 48px;

        & .about-title {
            font-weight: 700;
            font-size: 64px;
            line-height: 78px;
            /* identical to box height */

            background: linear-gradient(90deg, #45ab39 0%, #92bf2b 116.46%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        & .about-text {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #c1c1c1;
            width: 704px;
        }
    }
}
.about-info-box {
    display: flex;
    justify-content: center;
    align-items: center;

    & .about-info-item {
        width: 394.03px;
        padding: 0 50px;
        font-weight: 400;
        font-size: 22.6235px;
        line-height: 28px;

        & .title {
            color: #6bbe35;
            font-weight: 700;
            padding-bottom: 10px;
        }
    }
}

.about-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    background: linear-gradient(90deg, #45ab39 0%, #92bf2b 116.46%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    padding-bottom: 91px;
    margin-top: 49px;
}

.about-sub-title {
    font-weight: 700;
    font-size: 22.9996px;
    line-height: 28px;
    color: #c1c1c1;
    width: 70%;
    margin-left: 237px;
}

.directions {
    display: flex;

    & .text {
        width: 50%;
        margin-left: 237px;

        ul {
            list-style: none;

            & li::before {
                content: "⬤";
                color: #68b433;
                font-weight: bold;
                display: inline-block;
                margin-right: 10px;
                font-size: 16px;
            }
            & li {
                font-weight: 700;
                font-size: 22.9996px;
                line-height: 28px;
                padding: 10px;

                color: #c1c1c1;
            }
        }
    }
}

.kompleks {
    display: flex;
    padding-bottom: 108px;
    ul {
        list-style: none;
        margin-left: 237px;
        margin-top: 65px;

        & li::before {
            content: "⬤";
            color: #68b433;
            font-weight: bold;
            display: inline-block;
            margin-right: 10px;
            font-size: 16px;
        }
        & li {
            font-weight: 700;
            font-size: 22.9996px;
            line-height: 28px;
            padding: 10px;

            color: #c1c1c1;
        }
    }
}

.info-block {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    .block-item {
        padding: 50px 30px;
        height: 200px !important;


        & .title-green {
            font-size: 22.7267px;
            line-height: 28px;
            text-align: center;
            font-weight: 700;
            color: #92bf2b;

            background-clip: text;
            padding-bottom: 26px;
        }
        .title {
            font-weight: 700;
            font-size: 22.7267px;
            line-height: 28px;
            text-align: center;
            color: #ffffff;
            padding-bottom: 26px;
        }
        & .text-dark {
            font-weight: 700;
            font-size: 20.894px;
            line-height: 25px;
            text-align: center;
            color: #ffffff;
        }

        & .text-light {
            font-weight: 700;
            font-size: 20.894px;
            line-height: 25px;
            text-align: center;
            color: #c1c1c1;
        }
    }

    .dark {
        background: linear-gradient(180deg, #92bf2b 0%, #68b433 100%);
    }
    .light {
        background: #e9e9e9;
    }
}

@media screen and (max-width: 425px) {
    .about-banner {
        height: 354px;
        text-align: left;
        & .about-caption {
            margin: 0;
            width: 85%;
            margin: 24px;

            & .about-title {
                text-align: left;
                font-weight: 700;
                font-size: 15px;
                margin: 0;
                line-height: 18px;
                padding: 0;
            }

            & .about-text {
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                margin-top: 24px;
                width: 322px;
            }
        }
    }

    .about-info-box {
        flex-direction: column;
        width: 100%;
        .about-info-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            margin-top: 8px;

            width: 100%;
            padding: 0;
            .title {
                margin-left: 21px;
            }
            .text {
                width: 325px;
                margin-left: 21px;
                padding: 0 0 15px 0;
            }
        }
        .about-info-item:nth-child(1),
        .about-info-item:nth-child(2) {
            box-shadow: 1px 10px 15px -15px #000;
        }
    }
    .about-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 24px;
    }

    .directions {
        flex-direction: column;
        .text {
            margin-left: 0;
            width: 100%;
            background: #92bf2b;
        }

        ul {
            padding: 18px 28px;
            li {
                font-weight: 400 !important;
                font-size: 16px !important;
                line-height: 18px !important;
                color: #fff !important;
            }
            li::before {
                color: #fff !important;
            }
        }
    }
    .about-sub-title {
        width: 320px;
        font-weight: 200;
        font-size: 16px !important;
        line-height: 20px;
        margin-left: 40px;
    }
    .kompleks {
        ul {
            margin-left: 38px;

            li {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                padding: 0;
                margin-bottom: 32px;
                color: #68b433;
            }
            li::before {
                display: none;
            }
        }
    }

    .info-block {
        width: 100%;
        display: block;

        .light:nth-child(3) {
            background: linear-gradient(180deg, #92bf2b 0%, #68b433 100%);
            .title-green{
                color: #fff;
            }
            
        }

        .dark:nth-child(4) {
            background: #e9e9e9;

            .title {
                color: #45AB39;
            }
        }
        .block-item {
            height: 16px !important;
            display: inline-block;
            padding: 36px 0;

            .title,
            .title-green {
                font-weight: 700;
                font-size: 13px;
                line-height: 20px;
            }

            width: 50% !important;
            .text-dark {
                display: none !important;
            }
            .text-light {
                display: none !important;
            }
        }
    }
}

@media screen and (min-width:1024px) and (max-width:1440px) {
    .directions {
        .text {
            margin-left: 100px ;
        }
    }
    
   
}